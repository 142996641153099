<template>
  <div>
    <Row>
      <i-col span="24">
        <Divider dashed><span class="divider-text">任务汇总</span></Divider>
        <Table
          stripe
          size="small"
          :data="taskSummary"
          :columns="dataColumns"
        ></Table>
      </i-col>
    </Row>

  </div>
</template>

<script>
export default {
  data () {
    return {
      taskSummary: [],
      dataColumns: [
        { title: '任务类型', align: 'left', key: 'taskTypeName' },
        {
          title: '数量',
          align: 'right',
          key: 'taskTypeName',
          render: (h, param) => {
            return h('div', [
              h('span',
                this.summaryType === 'pendding' ? param.row.pendingTaskCount : param.row.taskCount)
            ])
          }
        }
      ]
    }
  },
  created () {
  },
  computed: {
    taskCount () {
      return this.$store.state.approvalTask.taskCount
    },
    summaryType () {
      return this.$store.state.approvalTask.summaryType
    }
  },
  watch: {
    taskCount (val) {
      this.taskSummary = val
    }
  }
}
</script>
